:root{
    /* For ghcs */
    --alert-color: #002f6c; 
    /* For iqops */
    /* --alert-color: #4eafa9;  */
}

.swal2-html-container {
    font-family: 'Roboto';
}

.swal2-title {
    font-family: 'Roboto-Bold';
}

.swal2-styled.swal2-cancel {
    background-color: var(--alert-color);
}

button.swal2-confirm.swal2-styled {
    background-color: var(--alert-color);
} 
/* *::-webkit-scrollbar {
    width: 0px;
} */
*::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  *::-webkit-scrollbar-track {
    background: none;        /* color of the tracking area */
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(207, 216, 220);    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
    border: 3px solid none;  /* creates padding around scroll thumb */
    padding-right: 5px;
  }