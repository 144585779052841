@media screen and (min-width: 300px) and (max-width: 349px) {
    .viewContainer {
        min-width: 300px;
        padding-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 350px) and (max-width: 480px) {
    .viewContainer {
        min-width: 350px;
        padding-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .viewContainer {
        min-width: 450px;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (min-width: 769px) {
    .viewContainer {
        min-width: 600px;
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }
}

.signature {
    /* border: 1px black; */
    width: 80%;
    max-width: 500px;
    min-height: 250px;
    /* margin: auto; */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 7px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}