.scrollable-list {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.scrollable-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.agentsContainer{
  background-color: white;
}
.agentsContainer::-webkit-scrollbar {
  width: 0px;
}
